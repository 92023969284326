import React, { useState, useEffect } from 'react'

import { DotsCircleLoader } from '@reactiveonline/frontend_shared_components'
import ProductItem from '../../products/ProductItem'
import listStyles from '../../products/listStyles'

export default function Product({ item, deviceType, extraData }) {
  const { settings } = item
  const { id, classes, viewType } = settings
  const appProps = extraData

  const [products, setProducts] = useState([])
  const [loading, setLoading] = useState(false)

  useEffect( () => {
    fetchProduct()
  }, [])

  async function fetchProduct() {
    const productItem = settings.item

    if (productItem) {
      setLoading(true)

      Rails.ajax({
        type: 'GET',
        url: `${ appProps.selectedProductsPath }?items=${ JSON.stringify([productItem]) }`,
        dataType: 'json',
        success: result => {
          if (result.items) {
            setProducts(result.items)
          }
        }
      })

      setLoading(false)
    }
  }

  return (
    <div
      id={ id || item.id }
      className={ `rb-product ${ classes || '' } items-list ${ viewType === 'list' ? 'list' : '' } flex-box flex-wrap` }
    >
      { products.length > 0 && products.map( (product, index) => (
        <ProductItem
          key={ index }
          appProps={ appProps }
          product={ product }
          productsPerRowClass='one_item'
          quickViewActions={ appProps.quickViewActions }
          isList={ viewType === 'list' }
        />
      ))}

      <style dangerouslySetInnerHTML={{ __html: `
        #${ id || item.id } { ${ listStyles(1, viewType === 'list') }}
      `}}/>

      { loading &&
        <div className='flex-box items-center content-center flex-1'>
          <DotsCircleLoader/>
        </div>
      }
    </div>
  )
}
