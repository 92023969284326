import React, { lazy } from 'react'
import { App, AuthorizableModal } from '@reactiveonline/frontend_shared_components'
import HeaderIcons from './header/HeaderIcons'
import GlobalProductSearch from './shared/GlobalProductSearch'
import { additionalElements } from './builder/additionalElements'
import ProductSearch from "./shared/ProductSearch"
import TrackOrder from './orders/TrackOrder'
import { dynamicBlocks } from './builder/dynamicBlocks'

// pass 'packageComponent' if the component should be imported inside the package
// pass 'includePageBuilder' if you want to include the additionalElements of the page builder

export default function AppLayout(props) {
  let PageContent = null
  if (!props.packageComponent) {
    PageContent = lazy( () => import(`./${ props.componentPath }`) )
  }

  return (
    <App
      { ...props }
      packageComponent={ props.packageComponent && !(props.appProps.userFormLoginType && props.appProps.notAuthorized) }
      PageContent={ props.appProps.userFormLoginType && props.appProps.notAuthorized ? AuthorizableModal : PageContent }
      appProps={{
        ...props.appProps,
        HeaderIcons: HeaderIcons,
        ProductSearch: ProductSearch,
        GlobalProductSearch: GlobalProductSearch,
        TrackOrder: TrackOrder,
        pageBuilderAdditionalElements: props.appProps.includePageBuilder ? additionalElements({ appProps: props.appProps }) : {},
        dynamicBlocks: props.appProps.newBuilderEnabled ? dynamicBlocks : null
      }}
    />
  )
}
