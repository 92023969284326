import React, { useState, useEffect, useCallback } from "react"
import { Loader } from '@reactiveonline/frontend_shared_components'
import { SliderImages } from '@reactiveonline/frontend_shared_components'

import AddToCart from "./AddToCart"
import ProductStock from "./ProductStock"
import ModalProduct from "./ModalProduct"
import ModalVariant from "./ModalVariant"

import { getTranslatableField, constructTranslatableProductLink } from "../../helpers/utils"

export default function ProductItem({
  appProps, product, currency, isOnSlider, productsPerRowClass,
  quickViewActions, isBundleItem, isList
}) {
  const [displayPrice, setDisplayPrice] = useState(product.displayPrice)
  const [displayTaxPrice, setDisplayTaxPrice] = useState(product.displayTaxPrice)
  const [displayTotalPrice, setDisplayTotalPrice] = useState(product.displayTotalPrice)
  const [displayCompareToPrice, setDisplayCompareToPrice] = useState(product.displayCompareToPrice)
  const [quickViewModal, setQuickViewModal] = useState(false)
  const [modalProduct, setModalProduct] = useState(null)
  const [modalVariant, setModalVariant] = useState(null)
  const [modalProductCategory, setModalProductCategory] = useState(null)
  const [loading, setLoading] = useState(false)

  const {
    translations, quickViewPath, darkBackground, itemBorder, itemShadow,
    productItems, currentLocale, defaultLocale, localeUrlPresent, skuVisible,
    priceVisible, stockAllowed, showStockInfo, showTextInsteadOfCompareToPrice
  } = appProps

  const orderStatus = product.productOrderStatus || {}
  const addToCartText = translations.products[orderStatus.canPreorder ? 'preorder_button' : ((orderStatus.canBackorder && !product.inStock) ? 'backorder_button' : 'add_to_cart_button')]
  const isBundle = product.type === 'bundle'

  useEffect(() => {
    document.addEventListener("keydown", escFunction, false);
    return () => {
      document.removeEventListener("keydown", escFunction, false);
    };
  }, []);

  function quickViewModalAjax(product) {
    setLoading(true)
    const isVariant = product.type == 'Shop::Variant'
    Rails.ajax({
      type: "GET",
      url: `${ quickViewPath }?id=${product.id}&is_bundle=${isBundle}&is_variant=${isVariant}&is_bundle_item=${isBundleItem}`,
      dataType: "json",
      success: res => {
        if (res.product){
          if (isVariant){
            setModalVariant(res.product)
          } else {
            setModalProduct(res.product)
          }
          setModalProductCategory(res.category)
          setQuickViewModal(true)
        }
        setLoading(false)
      }
    })
  }

  function truncateWebshorts() {
    const productWebshorts = product.webshorts[currentLocale]
    const webshortString = productWebshorts.reduce((str, webshort) => {
      const temp = str.length > 0 ? `, ${webshort}` : webshort
      str = (str + temp).length < 40 ? str + temp : str
      return str
    }, '')

    return webshortString + ' '
  }

  const escFunction = useCallback((event) => {
    if (event.key === "Escape") {
      setQuickViewModal(false)
    }
  }, []);

  const productImages = product.images.length > 0 ? product.images.map( image => {
    return {
      original: image.list_view,
      thumbnail: image.thumbnail,
      fullscreen: image.banner,
      originalAlt: image.file_name,
      thumbnailAlt: image.file_name,
      originalTitle: image.file_name
    }
  }
  ) : [{
    original: 'https://storage.googleapis.com/reactive_files_eu/no-image.jpg',
    thumbnail: 'https://storage.googleapis.com/reactive_files_eu/no-image.jpg',
    fullscreen: 'https://storage.googleapis.com/reactive_files_eu/no-image.jpg',
    originalAlt: 'No Image',
    thumbnailAlt: 'No Image',
    originalTitle: 'No Image'
  }]

  const darkBackgroundStyle = { background: '#f7f7f7' }
  const itemBorderStyle = { border: '1px solid #dddddd' }
  let productItemStyle = darkBackground ? darkBackgroundStyle : {}
  productItemStyle = itemBorder ? { ...productItemStyle, ...itemBorderStyle } : productItemStyle

  return (
    <>
      { quickViewActions && loading &&
        <Loader
          absolute
          size={ 'xlarge' }
          position={ 'right' }
          fullPage
        />
      }
      <div
        className={ `${ itemShadow ? 'tiles-box-shadow' : '' } ${ isOnSlider ? 'slider-product-item' : 'product-item' } ${ (productItems == '1' || isList) ? 'list' : '' } ${ productsPerRowClass || '' }` }
        style={ productItemStyle }
      >
        <div className="product-image" style={{ position: 'relative' }}>
          { quickViewActions && !isOnSlider &&
            <div  className='quick-actions-wrapper'>
              <div className='quick-actions-container flex-box items-center content-center'>
                <div className='quick-action pointer'><i className='fa-light fa-eye' onClick={ ()=> quickViewModalAjax(product) }/></div>
                <div className='quick-action pointer'>
                  <i  className='fa-light fa-arrow-up-right-from-square'
                      onClick={ ()=> window.open(constructTranslatableProductLink(product, currentLocale, defaultLocale, localeUrlPresent), '_blank') }/>
                </div>
              </div>
            </div>
          }
          { product.published ?
            <a href={ constructTranslatableProductLink(product, currentLocale, defaultLocale, localeUrlPresent) } rel="nofollow noindex" >
              <SliderImages
                images={ productImages }
                showThumbnails={ false }
                showFullscreenButton={ false }
                showNav={ false }
                showBullets={ false }
                lightbox={ false }
                thumbnailPosition={ 'bottom' }
              />
            </a>
            :
            <SliderImages
              images={ productImages }
              showThumbnails={ false }
              showFullscreenButton={ false }
              showNav={ false }
              showBullets={ false }
              lightbox={ false }
              thumbnailPosition={ 'bottom' }
            />
          }
          { product.badges &&
            <div className='flex-box items-center product-badges-wrapper flex-wrap'>
              { product.badges && product.badges.map( (badge, index) => (
                <img
                  key={ index }
                  src={ badge.source }
                  className='badge-icon'
                />
              ))}
            </div>
          }

          { product.onSale &&
            <div className='on-sale-badge flex-box items-center content-center'>
              { `-${Math.round(product.onSalePercentage)}%` }
            </div>
          }
        </div>
        <div className="product-details">
          <div className="product-info">
            <div className="product-name">
              { product.published ?
                <a href={ constructTranslatableProductLink(product, currentLocale, defaultLocale, localeUrlPresent) } rel="nofollow noindex" >
                  { getTranslatableField(product, 'title', currentLocale, false) }
                </a>
                :
                <>
                  { getTranslatableField(product, 'title', currentLocale, false) }
                </>
              }
            </div>

            { skuVisible && product.sku &&
              <div className='product-code'>
                <div className='label'>{ translations.products.sku }:</div>
                <div className='value'>{ product.sku }</div>
              </div>
            }

            { product.webshorts && product.webshorts[currentLocale] && product.webshorts[currentLocale].length > 0 &&
              <div className='product-webshorts flex-box content-space-between items-center'>
                <div className='flex-1'>
                  { truncateWebshorts() }
                </div>
                <a href={ constructTranslatableProductLink(product, currentLocale, defaultLocale, localeUrlPresent) } rel="nofollow noindex" >
                  { translations.general.more }
                </a>
              </div>
            }
          </div>
          { priceVisible && product.showPrice &&
            <>
              <div className='display-price-wrapper flex-box items-center'>
                <div className='display-price'>
                  { displayTotalPrice }
                </div>
                { product.onSale &&
                  ( showTextInsteadOfCompareToPrice ?
                    <div className='compare-to-price-text'>
                      { translations.products.discount_price }
                    </div> :
                    <div className='compare-to-price'>
                      { displayCompareToPrice }
                    </div>
                  )
                }
              </div>
              <div id={`product_list_item_below_price-${isBundle ? 'b' : 'p'}-${product.api_uid}`}></div>
            </>
          }

          {!isBundleItem &&
            <>
              { (!stockAllowed || (stockAllowed && product.inStock) || orderStatus.isOrderable) ?
                <div className="product-action">
                  { product.hasOptions ?
                    <a
                      href={ constructTranslatableProductLink(product, currentLocale, defaultLocale, localeUrlPresent) }
                      className='button'
                      rel="nofollow noindex"
                    >
                      { translations.products.view_product_button }
                    </a>
                    :
                    <AddToCart
                      appProps={ appProps }
                      productId={product.id}
                      quantity={1}
                      selectedOptionsIds={null}
                      addCartText={ addToCartText }
                      currency={currency}
                      product={product}
                      isBundle={product.type === 'bundle'}
                    />
                  }

                </div>
                :
                (showStockInfo ?
                  <div className="product-action">
                    <ProductStock
                      appProps={ appProps }
                      stock={ product.totalStock }
                      inStock={ product.inStock }
                    />
                  </div> : null)
              }
            </>
          }
        </div>
      </div>

      { quickViewModal &&
        <div className='quick-view-modal-wrapper flex-box items-center content-center'>
          <div  className='close close_icon flex-box items-center content-center pointer'
                onClick={ ()=> setQuickViewModal(false) }
          />
          <div className='quick-view-modal-container'>
            { modalProduct &&
              <ModalProduct
                modalProduct={ modalProduct }
                appProps={ appProps }
                currency={ currency }
                isBundle={ isBundle }
                isBundleItem={ isBundleItem }
                modalCategory={ modalProductCategory }
              />
            }
            { modalVariant &&
              <ModalVariant
                modalVariant={ modalVariant }
                appProps={ appProps }
                currency={ currency }
                isBundle={ isBundle }
                isBundleItem={ isBundleItem }
                modalCategory={ modalProductCategory }
              />
            }
          </div>
        </div>
      }
    </>
  )
}
