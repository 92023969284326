import React from 'react'

import ProductItem from './ProductItem'

import SwiperCore, {
  Navigation, Pagination, Scrollbar, A11y, Thumbs, Lazy, Zoom, Autoplay
} from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'

SwiperCore.use([
  Navigation, Pagination, Scrollbar, A11y, Thumbs, Lazy, Zoom, Autoplay
])

export default function ProductListSlider({
  appProps, itemsPerRow, sliderSettings, products, itemsPerRowClass
}) {
  const { arrows, dots, scrollbar, loop, autoMove, moveDuration } = sliderSettings

  return (
    <Swiper
      effect='slide'
      navigation={ !!arrows }
      pagination={ !!dots && { clickable: true } }
      scrollbar={ !!scrollbar && { draggable: true } }
      loop={ !!loop }
      autoplay={ !!autoMove && { delay: (moveDuration || 1) * 1000 } }
      spaceBetween={ 10 }
      style={{ height: '100%', width: '100%' }}
      breakpoints={{
        0: { slidesPerView: 1 },
        600: { slidesPerView: itemsPerRow == 1 ? 1 : 2 },
        768: { slidesPerView: itemsPerRow == 1 ? 1 : ( itemsPerRow == 6 || itemsPerRow == 5 || itemsPerRow == 4 ? 3 : itemsPerRow) },
        992: { slidesPerView: itemsPerRow == 1 ? 1 : (itemsPerRow == 6 || itemsPerRow == 5 ? 4 : itemsPerRow) },
        1080: { slidesPerView: itemsPerRow == 1 ? 1 : (itemsPerRow == 6 || itemsPerRow == 5 ? 5 : itemsPerRow) },
        1200: { slidesPerView: itemsPerRow == 1 ? 1 : itemsPerRow }
      }}
    >
      { products.map( product => (
        <SwiperSlide
          key={ product.type + product.uid }
          className={ itemsPerRowClass }
        >
          <ProductItem
            appProps={ appProps }
            product={ product }
            quickViewActions={ appProps.productQuickActions }
            isOnSlider
          />
        </SwiperSlide>
      ))}
    </Swiper>
  )
}
