import React from 'react'

export default function listStyles(productsPerRow, isList) {
  if (productsPerRow == 1 && isList) {
    return `
      .product-item {
        display: -webkit-box;
        display: -moz-box;
        display: -ms-flexbox;
        display: flex;
        width: 100%;
      }

      .product-image {
        width: 30%;
        margin-right: 5%;
      }
      .product-details {
        width: 65%;
      }

      @media screen and (min-width: 600px) {
        .product-image {
          width: 40%;
        }
        .product-details {
          width: 60%;
        }
      }
    `

  } else if (productsPerRow == 2) {
    return `
      @media screen and (min-width:600px) {
        .product-item {
          width: calc( 95% / 2 );
          margin-right: calc( 5% / 2 );
        }
        .product-item:last-child {
          margin-right: 0px;
        }
      }
    `

  } else if (productsPerRow == 3) {
    return `
      @media screen and (min-width:600px) {
        .product-item {
          width: calc( 95% / 2 );
          margin-right: calc( 5% / 2 );
        }
        .product-item:last-child {
          margin-right: 0px;
        }
      }
      @media screen and (min-width:850px) {
        .product-item {
          width: calc( 95% / 3 );
          margin-right: calc( 5% / 3 );
        }
        .product-item:last-child {
          margin-right: 0px;
        }
      }
    `

  } else if (productsPerRow == 4) {
    return `
      @media screen and (min-width:600px) {
        .product-item {
          width: calc( 95% / 2 );
          margin-right: calc( 5% / 2 );
        }
        .product-item:last-child {
          margin-right: 0px;
        }
      }
      @media screen and (min-width:768px) {
        .product-item {
          width: calc( 95% / 3 );
          margin-right: calc( 5% / 3 );
        }
        .product-item:last-child {
          margin-right: 0px;
        }
      }
      @media screen and (min-width:992px) {
        .product-item {
          width: calc( 95% / 3 );
          margin-right: calc( 5% / 3 );
        }
        .product-item:last-child {
          margin-right: 0px;
        }
      }
      @media screen and (min-width:1200px) {
        .product-item {
          width: calc( 95% / 4 );
          margin-right: calc( 5% / 4 );
        }
        .product-item:last-child {
          margin-right: 0px;
        }
      }
    `
  } else if (productsPerRow == 5) {
    return `
      @media screen and (min-width:600px) {
        .product-item {
          width: calc( 95% / 2 );
          margin-right: calc( 5% / 2 );
        }
        .product-item:last-child {
          margin-right: 0px;
        }
      }
      @media screen and (min-width:768px) {
        .product-item {
          width: calc( 95% / 3 );
          margin-right: calc( 5% / 3 );
        }
        .product-item:last-child {
          margin-right: 0px;
        }
      }
      @media screen and (min-width:992px) {
        .product-item {
          width: calc( 95% / 4 );
          margin-right: calc( 5% / 4 );
        }
        .product-item:last-child {
          margin-right: 0px;
        }
      }
      @media screen and (min-width:1200px) {
        .product-item {
          width: calc( 95% / 5 );
          margin-right: calc( 5% / 5 );
        }
        .product-item:last-child {
          margin-right: 0px;
        }
      }
    `
  }

  return ''
}
