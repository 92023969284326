import React, { useState, useEffect } from 'react'

import { DotsCircleLoader } from '@reactiveonline/frontend_shared_components'
import ProductItem from '../../products/ProductItem'
import ProductListSlider from '../../products/ProductListSlider'
import listStyles from '../../products/listStyles'

export default function ProductList({ item, deviceType, extraData }) {
  const { settings } = item
  const {
    id, classes, viewType, itemsPerRow, itemsLimit, selectionCriterion, items,
    filtering, sliderSettings
  } = settings
  const appProps = extraData

  const itemsPerRowClass = getItemsPerRowClass()

  const [products, setProducts] = useState([])
  const [loading, setLoading] = useState(false)

  useEffect( () => {
    fetchProducts()
  }, [])

  async function fetchProducts() {
    if (!['custom', 'category'].includes(selectionCriterion) || items.length) {
      let params = `product_limit=${ itemsLimit }&selected_product_items=${ selectionCriterion }`

      if (selectionCriterion === 'custom') {
        params += `&items=${ JSON.stringify(items) }`

      } else if (selectionCriterion === 'category') {
        params += `&categories=${ JSON.stringify(items) }&filtering=${ filtering }`
      }

      setLoading(true)

      Rails.ajax({
        type: 'GET',
        url: `${ appProps.selectedProductsPath }?${ params }`,
        dataType: 'json',
        success: result => {
          if (result.items) {
            setProducts(result.items)
          }
        }
      })

      setLoading(false)
    }
  }

  function getItemsPerRowClass() {
    switch (parseInt(itemsPerRow)) {
      case 1:
        return 'one_item'

      case 2:
        return 'two_items'

      case 3:
        return 'three_items'

      case 4:
        return 'four_items'

      case 5:
        return 'five_items'

      case 6:
        return 'six_items'
    }
  }

  return (
    <div
      id={ id || item.id }
      className={ `rb-product-list ${ classes || '' } items-list ${ viewType === 'list' ? 'list' : '' } flex-box flex-wrap` }
    >
      { viewType !== 'slider' && products.length > 0 && products.map( (product, index) => (
        <ProductItem
          key={ index }
          appProps={ appProps }
          product={ product }
          productsPerRowClass={ itemsPerRowClass }
          quickViewActions={ appProps.quickViewActions }
          isList={ viewType === 'list' }
        />
      ))}

      { viewType === 'slider' && products.length > 0 &&
        <ProductListSlider
          appProps={ appProps }
          itemsPerRow={ itemsPerRow }
          sliderSettings={ sliderSettings || {} }
          products={ products }
          itemsPerRowClass={ itemsPerRowClass }
        />
      }

      <style dangerouslySetInnerHTML={{ __html: `
        #${ id || item.id } { ${ listStyles(itemsPerRow, viewType === 'list') }}
      `}}/>

      { loading &&
        <div className='flex-box items-center content-center flex-1'>
          <DotsCircleLoader/>
        </div>
      }
    </div>
  )
}
