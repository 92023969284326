import React, { useState } from 'react'

import { getCookieExpirationDate } from '../../helpers/utils'
import listStyles from './listStyles'

import cookie from 'react-cookies'

export default function ListGridToggle({ appProps, setIsList }) {
  const listGridCookie = cookie.load('listGridOption')

  const [savedListGridOption, setSavedListGridOption] = useState(!!listGridCookie)
  const [currentOption, setCurrentOption] = useState(listGridCookie ? listGridCookie : appProps.productsPerRow)

  function saveListGridOption(option) {
    if (setIsList) {
      setIsList(option === 'list')
    }

    const expiration = getCookieExpirationDate(365)
    cookie.save('listGridOption', option, { expires: expiration })
    setSavedListGridOption(option)
  }

  return (
    <>
      <style dangerouslySetInnerHTML={{ __html: listStyles(currentOption === 'list' ? 1 : appProps.productsPerRow, currentOption === 'list' || appProps.productsPerRow == 1) }}/>

      <div className='list-grid-toggle'>
        <div
          className={`icon list_icon ${currentOption == 'list' ? 'active' : '' }`}
          onClick={ () => {
            saveListGridOption('list')
            setCurrentOption('list')
          }}
        />
        <div
          className={`icon grid_icon ${currentOption != 'list' ? 'active' : '' }`}
          onClick={ () => {
            saveListGridOption('grid')
            setCurrentOption(appProps.productsPerRow)
          }}
        />
      </div>
    </>
  )
}
