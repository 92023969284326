import React, { useState, useEffect } from "react"
import Select from "react-select"
import { SliderImages } from '@reactiveonline/frontend_shared_components'

import ProductDescription from "./ProductDescription"
import Options from "./Options"
import ProductShowBottom from "./ProductShowBottom"
import RelatedProducts from "./RelatedProducts"
import AddToCart from "./AddToCart"
import ProductStock from "./ProductStock"
import { customReactSelectStyles, customReactSelectTheme, getTranslatableField } from "../../helpers/utils"
import { loadProductViewAnalytics } from '../../helpers/analytics'

export default function ModalProduct({ modalProduct, appProps, currency, modalCategory, isBundleItem, isBundle }) {
  const product     = modalProduct
  const upcEntry    = product.attributes.filter(attr => attr.prototypeAttributeKey === "UPC")[0]
  const brandEntry  = product.attributes.filter(attr => attr.prototypeAttributeKey === "Brand")[0]
  let upcValue = ''
  if(upcEntry) {
    upcValue = getTranslatableField(upcEntry, 'title', appProps.currentLocale, false)
  }
  let productImages = product.images.length > 0 ? product.images.map( image => {
    return {
      original: image.preview,
      thumbnail: image.thumbnail,
      fullscreen: image.banner,
    }
  }
  ) : [{
    original: 'https://storage.googleapis.com/reactive_files_eu/no-image.jpg',
    thumbnail: '',
    fullscreen: ''
  }]

  const [displayPrice, setDisplayPrice] = useState(product.displayPrice)
  const [displayTaxPrice, setDisplayTaxPrice] = useState(product.displayTaxPrice)
  const [displayTotalPrice, setDisplayTotalPrice] = useState(product.displayTotalPrice)
  const [displayCompareToPrice, setDisplayCompareToPrice] = useState(product.displayCompareToPrice)
  const [sku, setSku] = useState(product.sku)
  const [upc, setUpc] = useState(upcValue)
  const [inStock, setInStock] = useState(product.inStock)
  const [stock, setStock] = useState(product.totalStock)
  const [onSale, setOnSale] = useState(product.onSale)
  const [onSalePercentage, setOnSalePercentage] = useState(product.onSalePercentage)
  const [loading, setLoading] = useState(false)
  const [selectedOptionsIds, setSelectedOptionsIds] = useState([])
  const [selectedQuantity, setSelectedQuantity] = useState(1)
  const [images, setImages] = useState(productImages)

  useEffect(() => {
    loadProductViewAnalytics(product, appProps)
  }, []);

  const productDescription = getTranslatableField(product, 'description', appProps.currentLocale, false)
  const productSubtitle = getTranslatableField(product, 'subtitle', appProps.currentLocale, false)
  let defaultQuantityOption = {value: 1, label: '1'}

  function updateStateProduct(pricesData) {
    let variantImages = pricesData.images ? pricesData.images.map(image => {
      return {
        original: image.preview,
        thumbnail: image.thumbnail,
        fullscreen: image.banner,
        originalAlt: image.file_name,
        thumbnailAlt: image.file_name,
        originalTitle: image.file_name
      }
    }) : []

    let mergedImages = variantImages.concat(productImages)

    if(mergedImages.length > 1){
      mergedImages = mergedImages.filter(img => img.original !== 'https://storage.googleapis.com/reactive_files_eu/no-image.jpg')
    }

    const upcEntry = product.attributes.filter(attr => attr.prototypeAttributeKey === "UPC")[0]
    let upcValue = ''
    if(upcEntry) {
      upcValue = getTranslatableField(upcEntry, 'title', appProps.currentLocale, false)
    }

    setDisplayPrice(pricesData.displayPrice)
    setDisplayTaxPrice(pricesData.displayTaxPrice)
    setDisplayTotalPrice(pricesData.displayTotalPrice)
    setDisplayCompareToPrice(pricesData.displayCompareToPrice)
    setSku(pricesData.sku || product.sku)
    setUpc(pricesData.upc || upcValue)
    setInStock('inStock' in pricesData ? pricesData.inStock : product.inStock)
    setStock('totalStock' in pricesData ? pricesData.totalStock : product.totalStock)
    setOnSale('onSale' in pricesData ? pricesData.onSale : product.onSale)
    setOnSalePercentage('onSalePercentage' in pricesData ? pricesData.onSalePercentage : product.onSalePercentage)
    setSelectedQuantity(1)
    setImages(mergedImages)
    setLoading(false)
  }

  function updateStateSelectedOptionsIds(optionsIds) {
    setSelectedOptionsIds(optionsIds)
  }

  return (
    <>
      <div className='quick-view-product-wrapper flex-box flex-wrap'>
        <div className="product-image" style={{ position: 'relative' }}>
          <SliderImages
            images={ images }
            showThumbnails={ false }
            showFullscreenButton={ false }
            showNav={ false }
            showBullets={ false }
            lightbox={ false }
            thumbnailPosition={ 'bottom' }
          />
          { product.badges &&
            <div className='flex-box items-center product-badges-wrapper flex-wrap'>
              { product.badges && product.badges.map( (badge, index) => (
                <img
                  key={ index }
                  src={ badge.source }
                  className='badge-icon'
                />
              ))}
            </div>
          }
          { !isBundleItem && onSale &&
            <div className='on-sale-badge flex-box items-center content-center'>
              { onSale && `-${Math.round(onSalePercentage)}%` }
            </div>
          }
        </div>
        <div className="product-details">
          <div className="product-info">
            <h1>
              { getTranslatableField(product, 'title', appProps.currentLocale, false) }
            </h1>

            { productSubtitle && productSubtitle !== '' &&
              <div dangerouslySetInnerHTML={{__html: productSubtitle}}></div>
            }

            { product.webshorts && product.webshorts[appProps.currentLocale] && product.webshorts[appProps.currentLocale].length > 0 &&
              <div style={{ marginBottom: 15 }}>
                { product.webshorts[appProps.currentLocale].join(', ') }
              </div>
            }

            { !isBundleItem && product.showPrice &&
              <>
                <div className='display-price-wrapper flex-box items-center'>
                  <div className='display-price'>
                    { displayTotalPrice }
                  </div>
                  { onSale &&
                    ( appProps.showTextInsteadOfCompareToPrice ?
                      <div className='compare-to-price-text'>
                        { appProps.translations.products.discount_price }
                      </div> :
                      <div className='compare-to-price'>
                        { displayCompareToPrice }
                      </div>
                    )
                  }
                </div>
                { product.hasTax && !product.appliedTax.includedInPrice &&
                  <div className="tax-amount">
                    { `(${appProps.translations.products.includes} ${product.appliedTax.title} ${Math.round(product.appliedTax.rate * 100)}%)` }
                  </div>
                }
              </>
            }
            { sku &&
              <div className='product-code'>
                <div className='label'>{ appProps.translations.products.sku }:</div>
                <div className='value'>{ sku }</div>
              </div>
            }
            { upc &&
              <div className='product-code'>
                <div className='label'>{ appProps.translations.products.upc }:</div>
                <div className='value'>{ upc }</div>
              </div>
            }
            { brandEntry && brandEntry.productAttributeImageSource &&
              <div className="flex-box">
                <img
                  src={ brandEntry.productAttributeImageSource }
                  style={{
                    width: "120px",
                    height: "100%",
                    objectFit: "contain",
                    marginBottom: 10
                  }}
                  alt={ getTranslatableField(brandEntry, 'title', appProps.currentLocale, false) }
                />
              </div>
            }
            { appProps.stockAllowed && appProps.showStockInfo &&
              <ProductStock
                appProps={ appProps }
                stock={ product.stock }
                inStock={ product.inStock }
              />
            }
          </div>

          { !isBundleItem &&
              <div className="product-options-wrapper">
                <Options
                  appProps={ appProps }
                  loading={ loading }
                  optionTypes={ product.optionTypes }
                  updateStateProduct={ updateStateProduct }
                  setLoading={ setLoading }
                  productId={ product.id }
                  updateStateSelectedOptionsIds={ updateStateSelectedOptionsIds }
                />
              </div>
          }
          { !isBundleItem &&
            <div className="product-options">
              {(!appProps.stockAllowed || (appProps.stockAllowed && inStock && product.showPrice)) &&
                <div className="product-option-wrapper quantity-dropdown-wrapper">
                  <div className='option-type-title-wrapper '>
                    { appProps.translations.cart.quantity }
                  </div>
                  <Select
                    theme={ customReactSelectTheme }
                    styles={ customReactSelectStyles }
                    isSearchable={ false }
                    options={ Array(stock === null || stock > appProps.maxProductQuantity ? appProps.maxProductQuantity : stock).fill().map((x, i) => {
                      return {value: i+1, label: `${i+1}`}
                    })}
                    defaultValue={ defaultQuantityOption }
                    onChange={(selected) => {
                      setSelectedQuantity(selected.value)
                    }}
                  />
                </div>
              }

              {(!appProps.stockAllowed || (appProps.stockAllowed && inStock)) &&
                <AddToCart
                  appProps={ appProps }
                  productId={ product.id }
                  selectedOptionsIds={ selectedOptionsIds }
                  quantity={ selectedQuantity }
                  addCartText={ appProps.translations.products.add_to_cart_button }
                  currency={ currency }
                  product={ product }
                  isBundle={ isBundle }
                />
              }
            </div>
          }
        </div>
      </div>

      <div style={{ marginTop: 40 }}>
        <ProductShowBottom
          appProps={ appProps }
          product={ product }
          category={ modalCategory }
          productPageBottomLayout={ 'tabbed' }
          isBundle={ isBundle }
          isBundleItem={ isBundleItem }
        />
      </div>
    </>
  )
}
