import React from "react"

import CustomSelect from "../shared/CustomSelect"
import ListGridToggle from './ListGridToggle'
import { sortingOptions, pagingOptions } from "../../helpers/elements"
import { constructProductsListUrl } from "../../helpers/utils"

export default function Sorting({
  appProps, productParams, setIsList
}) {
  const {
    orderBy, order, page, perPage, keyword, categoryMenusSlugs, filters,
    hidePriceSortingOptions, lowerPriceLimit, upperPriceLimit
  } = productParams

  let options = sortingOptions(appProps.translations)
  if (hidePriceSortingOptions){
    options = options.filter(option => !option.value.startsWith("price"))
  }

  if (!keyword) {
    options = options.filter(option => option.value !== 'relevancy')
  }

  let selectedSortingOption = options.filter(option => option.value === `${orderBy}-${order}`)[0]

  const perPageOptions = pagingOptions()
  let selectedPagingOption = perPageOptions.filter(option => option.value === `${perPage}`)[0]

  return (
    <div className="select-container sort-products">
      <ListGridToggle
        appProps={ appProps }
        setIsList={ setIsList }
      />
      <div className="select-wrapper">
        <div className="select-box">
          <CustomSelect
            appProps={ appProps }
            options={ options }
            onClick={(option) => {
              let orderParams = option.value.split('-')
              const urlParams = {
                keyword: keyword,
                order_by: orderParams[0],
                order: orderParams[1],
                per_page: perPage
              }

              if (upperPriceLimit && upperPriceLimit != "") {
                urlParams.upper_price_limit = upperPriceLimit
              }
              if (lowerPriceLimit && lowerPriceLimit != "") {
                urlParams.lower_price_limit = lowerPriceLimit
              }
              window.location.href = constructProductsListUrl(urlParams, categoryMenusSlugs, filters, appProps.localeUrlPresent ? appProps.currentLocale : null)
            }}
            value={ selectedSortingOption ? selectedSortingOption.title : null }
          />
        </div>
      </div>

      <div className="select-wrapper">
        <div className="select-box">
          <CustomSelect
            appProps={ appProps }
            options={ perPageOptions }
            onClick={(option) => {
              const urlParams ={
                keyword: keyword,
                order_by: orderBy,
                order: order,
                per_page: option.value
              }

              if (upperPriceLimit && upperPriceLimit != "") {
                urlParams.upper_price_limit = upperPriceLimit
              }
              if (lowerPriceLimit && lowerPriceLimit != "") {
                urlParams.lower_price_limit = lowerPriceLimit
              }
              window.location.href = constructProductsListUrl(urlParams, categoryMenusSlugs, filters, appProps.localeUrlPresent ? appProps.currentLocale : null)
            }}
            value={ selectedPagingOption ? selectedPagingOption.title : null }
          />
        </div>
      </div>
    </div>
  )
}
