import React, { useState, useEffect, useCallback } from "react"
import ProductItem from "./ProductItem"
import SideFilters from "./SideFilters"
import Sorting from "./Sorting"
import Pagination from "./Pagination"
import ActiveFilters from "./ActiveFilters"

import cookie from 'react-cookies'

import { loadProductListAnalytics } from "../../helpers/analytics"
import { constructProductsListUrl } from "../../helpers/utils"

export default function Products({
  appProps, containerClass, productParameters, hideProductsCount,
  filtersAllowed, hideSorting, currency, productsList, relatedCategoriesPath
}) {
  const listGridCookie = cookie.load('listGridOption')

  const [products, setProducts] = useState(productsList)
  const [productParams, setProductParams] = useState(productParameters)
  const [productFilters, setProductFilters] = useState(null)
  const [appliedFilterOptions, _setAppliedFilterOptions] = useState(productParams.filters)
  const [toggleFiltersSidebar, setToggleFiltersSidebar] = useState(false)
  const [upperPriceLimit, setUpperPriceLimit] = useState(productParameters.upperPriceLimit || "")
  const [lowerPriceLimit, setLowerPriceLimit] = useState(productParameters.lowerPriceLimit || "")
  const [isList, setIsList] = useState(listGridCookie === 'list' || appProps.productsPerRow == 1)

  useEffect(() => {
    document.addEventListener("keydown", escFunction, false)
    return () => {
      document.removeEventListener("keydown", escFunction, false)
    }
  }, [])

  useEffect(() => {
    loadProductListAnalytics(products, appProps)
    if (productParameters.upperPriceLimit || productParameters.lowerPriceLimit || productParameters.onSale){
      if (productParameters.upperPriceLimit || productParameters.lowerPriceLimit){
        submitFilterOption('price_range', { lowerPriceLimit: productParameters.lowerPriceLimit || "", upperPriceLimit: productParameters.upperPriceLimit || "" }, false)
      }
      if (productParameters.onSale){
        submitFilterOption('on_sale', 'true', false)
      }
    } else if(filtersAllowed) {
      loadProductFilters()
    }
  }, [])

  function setAppliedFilterOptions(newAppliedFilterOptions){
    _setAppliedFilterOptions(newAppliedFilterOptions)
    loadProductFilters(newAppliedFilterOptions)
  }

  const escFunction = useCallback((event) => {
    if (event.key === "Escape") {
      setToggleFiltersSidebar(false)
    }
  }, [])

  function loadProductFilters(filterOptions = appliedFilterOptions) {
    let formData = new FormData()
    formData.append('applied_filters', JSON.stringify(filterOptions))
    let url = `${appProps.localeUrlPresent ? '/' + appProps.currentLocale : ''}/load_filters`
    if (productParams.categoryId){
      url = url + '?category=' + productParams.categoryId
    }

    if ((filterOptions.price_range) && (upperPriceLimit !== "" || lowerPriceLimit !== "") || productParams.keyword){
      let hasQueryParams = true
      if (!url.includes('?')){
        hasQueryParams = false
        url = url + '?'
      }
      if (lowerPriceLimit !== ""){
        hasQueryParams = true
        url = url + `${hasQueryParams ? '&' : ''}lower_price_limit=${lowerPriceLimit}`
      }
      if (upperPriceLimit !== ""){
        hasQueryParams = true
        url = url + `${hasQueryParams ? '&' : ''}upper_price_limit=${upperPriceLimit}`
      }
      if (productParams.keyword){
        url = url + `${hasQueryParams ? '&' : ''}keyword=${productParams.keyword}`
      }
    }

    Rails.ajax({
      type: "POST",
      url: url,
      dataType: 'json',
      accept: 'json',
      data: formData,
      success: res => {
        let productFilters = res
        if(!res.error) {
          let updatedFilterOptions = JSON.parse(JSON.stringify(filterOptions))
          if(updatedFilterOptions.brand && productFilters.brandFilters) {
            updatedFilterOptions.brand = updatedFilterOptions.brand.filter(b => {
              return !!Object.keys(productFilters.brandFilters).filter(k => {
                const filterValues = JSON.parse(k)
                return filterValues && filterValues[1] === b && productFilters.brandFilters[k] > 0
              })[0]
            })
          }

          if(productFilters.optionFilters) {
            Object.keys(productFilters.optionFilters).map(o => {
              if(updatedFilterOptions[o]) {
                updatedFilterOptions[o] = updatedFilterOptions[o].filter(fo => {
                  return !!Object.keys(productFilters.optionFilters[o]).filter(k => {
                    const filterValues = JSON.parse(k)
                    return filterValues && filterValues[1] === fo && productFilters.optionFilters[o][k] > 0
                  })[0]
                })
              }
            })
          }

          setProductFilters(productFilters)
          _setAppliedFilterOptions(updatedFilterOptions)
        }
      }
    })
  }

  function applyFilterOption(key, value) {
    let updatedFilters = JSON.parse(JSON.stringify(appliedFilterOptions))
    if(updatedFilters[key]) {
      if (key == "price_range"){
        if (value.lowerPriceLimit === "" && value.upperPriceLimit === ""){
          delete updatedFilters[key]
          setLowerPriceLimit("")
          setUpperPriceLimit("")
        }
        else {
          updatedFilters[key][0].upperPriceLimit = value.upperPriceLimit
          updatedFilters[key][0].lowerPriceLimit = value.lowerPriceLimit
        }
      } else {
        if(updatedFilters[key].indexOf(value) < 0) {
          updatedFilters[key].push(value)
        } else {
          updatedFilters[key].splice(updatedFilters[key].indexOf(value), 1)
          if(updatedFilters[key].length === 0) {
            delete updatedFilters[key]
          }
        }
      }
    } else {
        if (!(key == "price_range" && value.upperPriceLimit === "" && value.lowerPriceLimit === "")) {
          updatedFilters[key] = [value]
        }
    }

    return updatedFilters
  }

  function preConstructFilterUrl(key, value) {
    const appliedFilterOptions = applyFilterOption(key, value)
    return constructProductsListUrl(getProductRequestParams(), productParams.categoryMenusSlugs, appliedFilterOptions, appProps.localeUrlPresent ? appProps.currentLocale : null)
  }

  function getProductRequestParams(page) {
    return {
      keyword: productParams.keyword,
      order_by: productParams.orderBy,
      order: productParams.order,
      page: page || productParams.page,
      per_page: productParams.perPage > 48 ? 48 : productParams.perPage
    }
  }

  function submitFilterOption(key, value, firstSubmitted = true) {
    const updatedFilters = applyFilterOption(key, value)
    const page = firstSubmitted ? 1 : null
    const productRequestParams = getProductRequestParams(page)
    if (updatedFilters.price_range) {
      if (lowerPriceLimit && lowerPriceLimit !== "") {
        productRequestParams["lower_price_limit"] = lowerPriceLimit
      }
      if (upperPriceLimit && upperPriceLimit !== "") {
        productRequestParams["upper_price_limit"] = upperPriceLimit
      }
    }
    const {price_range, ...actualAppliedFilterOptions} = updatedFilters
    const updatedUrl = constructProductsListUrl(productRequestParams, productParams.categoryMenusSlugs, actualAppliedFilterOptions, appProps.localeUrlPresent ? appProps.currentLocale : null)
    setAppliedFilterOptions(updatedFilters)
    window.history.pushState({}, '', updatedUrl)
    filtersAjaxRequest(updatedUrl)
  }

  function clearAllFilters() {
    const updatedUrl = constructProductsListUrl(getProductRequestParams(1), productParams.categoryMenusSlugs, {}, appProps.localeUrlPresent ? appProps.currentLocale : null)
    setAppliedFilterOptions({})
    window.history.pushState({}, '', updatedUrl)
    setLowerPriceLimit("")
    setUpperPriceLimit("")
    filtersAjaxRequest(updatedUrl)
  }

  function clearOneTypeFilter(optionTypeKey) {
    let updatedFilters = JSON.parse(JSON.stringify(appliedFilterOptions))
    delete updatedFilters[optionTypeKey]
    const updatedUrl = constructProductsListUrl(getProductRequestParams(1), productParams.categoryMenusSlugs, updatedFilters, appProps.localeUrlPresent ? appProps.currentLocale : null)
    setAppliedFilterOptions(updatedFilters)
    window.history.pushState({}, '', updatedUrl)
    filtersAjaxRequest(updatedUrl)
  }

  function filtersAjaxRequest(url) {
    Rails.ajax({
      type: "GET",
      url: url,
      dataType: "json",
      success: res => {
        if(res.products) {
          setProducts(res.products)
          setProductParams(res.productParams)
          let productListTitleElm = document.getElementsByClassName('product-list-title')[0]
          if(productListTitleElm) {
            productListTitleElm.scrollIntoView({behavior: 'smooth', block: 'start'})
          }
        }
      }
    })
  }

  let noProductsFoundMessage = productParameters.keyword !== null ?
    `${ appProps.translations.products.no_products_found_for_keyword }${ productParameters.keyword }`
    : appProps.translations.products.no_products_found

  return (
    <>
      <div id='above_product_list'></div>
      <div className={ containerClass }>
        <div className={` product-list-wrapper ${ appProps.sidebarPosition }`}>
          { appProps.showCategories &&
            <SideFilters
              appProps={ appProps }
              productFilters={ productFilters }
              appliedFilterOptions={ appliedFilterOptions }
              submitFilterOption={ submitFilterOption }
              preConstructFilterUrl={ preConstructFilterUrl }
              clearOneTypeFilter={ clearOneTypeFilter }
              filtersAllowed={ filtersAllowed }
              categoryMenusSlugs={ productParams.categoryMenusSlugs }
              toggleFiltersSidebar={ toggleFiltersSidebar }
              setToggleFiltersSidebar={ setToggleFiltersSidebar }
              relatedCategoriesPath={ relatedCategoriesPath }
              lowerPriceLimit={ lowerPriceLimit }
              setLowerPriceLimit={ setLowerPriceLimit }
              upperPriceLimit={ upperPriceLimit }
              setUpperPriceLimit={ setUpperPriceLimit }
              keyword={ productParams.keyword }
            />
          }
          <div className="product-list">
            <div className='product-list-top-section flex-box flex-wrap items-center content-space-between'>
              <div className="product-list-title">
                <h1 className='flex-box items-center'>
                  { appProps.sidebarPosition == 'burger_sidebar' && appProps.currentDevice == 'desktop' &&
                    <div  style={{ fontSize: 30, marginRight: 10 }}
                          className='pointer toggle_icon'
                          onClick={ ()=> setToggleFiltersSidebar(prevState => !prevState) }/>
                  }
                  { productParams && productParams.categoryMenuTitle ? productParams.categoryMenuTitle : appProps.translations.products.product_list_title }
                </h1>
              </div>
              {!hideSorting &&
                <Sorting
                  appProps={ appProps }
                  productParams={ productParams }
                  setIsList={ setIsList }
                />
              }
            </div>
            { appProps.showCategories && filtersAllowed ?
              <ActiveFilters
                appProps={ appProps }
                appliedFilterOptions={ appliedFilterOptions }
                productFilters={ productFilters }
                submitFilterOption={ submitFilterOption }
                clearAllFilters={ clearAllFilters }
                currency={ currency }
              /> : <div style={{marginBottom: 20}}></div>
            }
            <div className="product-list-items">
              { products.length > 0 ?
                  products.map(product => {
                    return (
                      <ProductItem
                        appProps={ appProps }
                        product={ product }
                        key={ product.id }
                        currency={ currency.iso_code }
                        quickViewActions={ appProps.productQuickActions }
                        isList={ listGridCookie === 'list' || appProps.productsPerRow == 1 }
                      />
                    )
                  }) :
                  <div>{appProps.translations.products.no_products_found}</div>
              }
            </div>
            <div className="page-navigation-wrapper">
              <Pagination
                appProps={ appProps }
                productParams={ productParams }
              />
            </div>
          </div>
        </div>
      </div>
      <div id='below_product_list'></div>
    </>
  )
}
