import React, { useState, useEffect, useRef } from "react"
import { BankDetails } from '@reactiveonline/frontend_shared_components'

import ProductPresentableTitle from "../../shared/ProductPresentableTitle"

export default function OrderHistoryItem({ appProps, order, showEmail, openedOrderId, setOpenedOrderId }) {
  const [orderItem, setOrderItem] = useState(order)
  const showOrder = order.id === openedOrderId

  useEffect(() => {
    if(showOrder) {
      fetchOrder(orderItem.getOrderPath)
    }
  }, [openedOrderId])

  function handleToggleOrder() {
    if (showOrder){
      setOpenedOrderId(null)
    } else {
      setOpenedOrderId(order.id)
    }
  }

  function fetchOrder(getOrderPath) {
    Rails.ajax({
      type: "GET",
      url: getOrderPath,
      dataType: "json",
      success: res => {
        if(res.order) {
          setOrderItem(res.order)
        }
      },
      error: res => {

      }
    })
  }

  return (
    <>
      <div className={`history_rows pointer ${showOrder ? 'active' : ''}`} onClick={ handleToggleOrder } >
        <div className="row date">
          <h6>{ orderItem.completedAtFormatted }</h6>
        </div>
        <div className="row number">
          <h6>{ orderItem.number }</h6>
        </div>
        <div className="row total">
          <h6>{ orderItem.totalWithAdjustments }</h6>
        </div>
        <div className="row delivered">
          <h6>
            { appProps.translations.orders[`status_${orderItem.status}`] }
          </h6>
        </div>
        { showEmail &&
          <div className="row delivered">
            <h6>
              { orderItem.email }
            </h6>
          </div>
        }
        <div className="row actions content-end">
          <div className={`view ${showOrder ? 'up_icon' : 'down_icon'}`} />
        </div>
      </div>

      { showOrder &&
        <div className={`order-details-wrapper ${showOrder ? 'active' : ''}`} style={{ width: '100%' }}>
          { orderItem.paymentMethod &&
            <div className='flex-box'>
              <div className='section-title'>
                { appProps.translations.orders.payment_method }:&nbsp;
              </div>
              <div>
                { orderItem.paymentMethod.presentation }
              </div>
            </div>
          }

          { orderItem.shipment &&
            <div className='flex-box'>
              <div className='section-title'>
                { appProps.translations.orders.shipping_method_section_title }:&nbsp;
              </div>
              <div>
                { orderItem.shippingMethod?.title || '-' }
              </div>
            </div>
          }

          { orderItem.bankDetails && !!orderItem.bankDetails.length &&
            <div style={{ marginTop: 20 }}>
              <BankDetails
                appProps={ appProps }
                bankDetails={ orderItem.bankDetails }
              />
            </div>
          }

          <div className='flex-box content-space-between'>
            { orderItem.specialInstructions &&
              <div className='flex-1 flex-box flex-column'>
                <div className='section-title'>
                  { appProps.translations.checkout.order_notes }
                </div>
                <div>
                  { orderItem.specialInstructions }
                </div>
              </div>
            }

            { orderItem.pickupStore &&
              <div className='flex-1 flex-box flex-column'>
                <div className='section-title'>
                  { appProps.translations.checkout.pickup_store }
                </div>
                <div>
                  { orderItem.pickupStore }
                </div>
              </div>
            }
          </div>

          { orderItem.invoice &&
            <div className="order-detail-block flex-1" style={{ marginTop: 10 }}>
              <div className='section-title' style={{ textDecoration: 'underline' }}>
                { appProps.translations.invoices.invoice }
              </div>
              <div style={{ marginTop: 2 }}>
                <b>{ appProps.translations.invoices.companyName }</b>: { orderItem.invoice.company_name }
                { orderItem.invoice.vat_number &&
                  <><br/><b>{ appProps.translations.invoices.vatNumber }</b>: { orderItem.invoice.vat_number }</>
                }
                { orderItem.billingAddress &&
                  <><br/><b>{ appProps.translations.company.billing_address }</b>: { orderItem.billingAddress }</>
                }
                { orderItem.invoice.additional_info &&
                  <><br/><b>{ appProps.translations.invoices.additionalInfo }</b>: { orderItem.invoice.additional_info }</>
                }
              </div>
            </div>
          }

          <div className='section-title' style={{ margin: '10px 0px', textDecoration: 'underline' }}>
            { orderItem.lineItems && appProps.translations.orders.order_contents }
          </div>
          <div className='flex-box flex-column'>
            { orderItem.lineItems && orderItem.lineItems.map((line_item, index) => {
              const itemImage = !line_item.isBundleItem && line_item.sellable.images.length > 0 ? line_item.sellable.images[0].list_view : "https://storage.googleapis.com/reactive_files_eu/no-image.jpg"

              return (
                <div key={ index } className='product-row flex-box'>
                  <div className="flex-1">
                    { !line_item.isBundleItem &&
                      <img style= {{ height: 30 }} src={ itemImage } />
                    }
                  </div>
                  <div className="flex-1">
                    <div className='flex-box'>
                      { !line_item.isBundleItem &&
                        <ProductPresentableTitle appProps={appProps} entity={line_item.sellable} entityIndex={index} />
                      }
                      { line_item.options &&
                        <div className="flex-1">
                          <ul>
                            { line_item.options.map((option, index) => (
                              <li key={index}>
                                { option.optionTypeName }: <b>{ option.name }</b>
                              </li>
                            ))}
                          </ul>
                        </div>
                      }
                      { line_item.isBundleItem &&
                        <div>
                          {`${line_item.quantity} x `} { line_item.title }
                        </div>
                      }
                    </div>
                  </div>
                  <div className="flex-1">
                    x { line_item.quantity }
                  </div>
                  <div className="flex-1">
                    { line_item.displayTotalGross }
                  </div>
                </div>
              )
            })}
          </div>
          <div style={{ marginTop: 20 }}>
            <div className="flex-box flex-column content-end" style={{ width: '50%' }}>
              <div className="flex-box items-center content-space-between">
                <div>{ orderItem.subtotal && `${appProps.translations.cart.subtotal}:` }</div>
                <div>{ orderItem.subtotal }</div>
              </div>
              <div className="flex-box items-center content-space-between">
                <div>{ orderItem.vatTotal && `${appProps.translations.cart.vat}:` }</div>
                <div>{ orderItem.vatTotal }</div>
              </div>
              { orderItem.shippingCostNet &&
                <div className="flex-box items-center content-space-between">
                  <div>{ orderItem.shippingCostNet && `${appProps.translations.cart.delivery_cost}:` }</div>
                  <div>{ orderItem.shippingCostNet }</div>
                </div>
              }
              { orderItem.handlingFeeNet &&
                <div className="flex-box items-center content-space-between">
                  <div>{ orderItem.handlingFeeNet && `${appProps.translations.cart.handling_fee}:` }</div>
                  <div>{ orderItem.handlingFeeNet }</div>
                </div>
              }

              { orderItem.couponAdjustments && orderItem.couponAdjustments.length > 0 ?
                  <>
                    <div className="flex-box items-center content-space-between">
                      <div>
                        <div><b>{ `${appProps.translations.checkout.coupons_title}:` }</b></div>
                        { orderItem.couponAdjustments.map( couponAdjustment => (
                          <div key={ couponAdjustment.orderAdjustmentId }>
                            { couponAdjustment.code }
                          </div>
                        ))}
                      </div>
                      <div>
                        { orderItem.couponAdjustments.map( couponAdjustment => (
                          <div key={ couponAdjustment.orderAdjustmentId }>
                            -{ couponAdjustment.discount }
                          </div>
                        ))}
                      </div>
                    </div>
                    <div className="flex-box items-center content-space-between">
                      <div><b>{ appProps.translations.cart.total }:</b></div>
                      <div><b>{ orderItem.totalWithAdjustments }</b></div>
                    </div>
                  </>
                :
                  <div className="flex-box items-center content-space-between">
                    <div><b>{ orderItem.total && `${appProps.translations.cart.total}:` }</b></div>
                    <div><b>{ orderItem.total }</b></div>
                  </div>
              }
            </div>
          </div>
        </div>
      }
    </>
  )
}
